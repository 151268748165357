import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import dateformat from 'dateformat'

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    return (
        <article className="post-card">
            <Link to={url} className="post-card-image-link">
                {post.feature_image &&
                <img className="post-card-image" 
                    src={post.feature_image}
                    sizes = "(max-width: 1000px) 400px, 700px" />
                }
            </Link>
            <Link to={url} className="post-card-content-link">
                <header className="post-card-header">
                    <h2 className="post-card-title">{post.title}</h2>
                </header>
                <section className="post-card-excerpt">{post.excerpt}</section>
                <footer className="post-card-footer">
                    <div className="post-card-footer-left">
                        <div>{dateformat(new Date(post.published_at), `mmmm dS, yyyy`)}</div>
                    </div>
                    <div className="post-card-footer-right reading-time">
                        <div>{readingTime}</div>
                    </div>
                </footer>
            </Link>
        </article>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        published_at: PropTypes.string,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
